import { randomElement, randomInt } from './random';

import bg1 from './images/backgrounds/graveyard.png';
import bg2 from './images/backgrounds/spooky-house.png';
import space from './images/backgrounds/space.png';
import underwater from './images/backgrounds/underwater.png';
import woods from './images/backgrounds/woods.png';
import bloodmoon from './images/backgrounds/bloodmoon.png';
import fullmoon from './images/backgrounds/fullmoon.png';

import bigfoot from './images/portraits/bigfoot.png';
import chtu from './images/portraits/chtu.png';
import clusterjunk from './images/portraits/cluster-junk.png';
import egg from './images/portraits/egg.png';
import firehead from './images/portraits/firehead.png';
import frank from './images/portraits/frank.png';
import goss from './images/portraits/goss.png';
import jerseydevil from './images/portraits/jersey-devil.png';
import littleghost from './images/portraits/little-ghost.png';
import mothman from './images/portraits/mothman.png';
import padded from './images/backgrounds/padded.png';
import nightcrawler from './images/portraits/fresno-nightcrawler.png';
import ghost from './images/portraits/tall-ghost.png';
import pullpal from './images/portraits/pullpal.png';
import scoops from './images/portraits/scoops.png';
import worm from './images/portraits/worm.png';
import bugbear from './images/portraits/bugbear.png';
import nessie from './images/portraits/nessie.png';
import headless from './images/portraits/headless.png';
import skelly from './images/portraits/skelly.png';
import dogman from './images/portraits/dogman.png';
import baxter from './images/portraits/baxter.png';
import chupa from './images/portraits/chupa.png';
import cute from './images/portraits/cute-ghost.png';
import vampire from './images/portraits/vampire.png';
import hair from './images/portraits/hair.png';
import beholder from './images/portraits/beholder.png';
import slender from './images/portraits/slender.png';
import booty from './images/portraits/booty.png';

import bios from './data/bios.json';
import names from './data/names.json';
import namePrefixes from "./data/name-prefixes.json";
import nameSuffixes from "./data/name-suffixes.json";


export const backgroundPictures = [
  bg1,
  bg2,
  padded,
  underwater,
  space,
  fullmoon,
  bloodmoon,
  woods
];

export function generateBackgroundImage(index) {
  return randomElement(backgroundPictures);
}

export const portraitPictures = [
  nightcrawler,
  ghost,
  goss,
  chtu,
  bigfoot,
  egg,
  frank,
  mothman,
  clusterjunk,
  littleghost,
  worm,
  firehead,
  jerseydevil,
  scoops,
  pullpal,
  bugbear,
  nessie,
  headless,
  skelly,
  dogman,
  baxter,
  chupa,
  cute,
  vampire,
  hair,
  beholder,
  slender,
  booty
];

export function generatePortraitImage() {
  return randomElement(portraitPictures);
}

export function generateName() {
  const prefix = Math.random() < 0.1 ? randomElement(namePrefixes) : "";
  const suffix = Math.random() < 0.1 ? randomElement(nameSuffixes) : "";
  const name = randomElement(names);

  return [prefix, name, suffix].join(" ");
}

export function generateBio() {
  return randomElement(bios);
}

export function generateDeathDate() {
  const month = randomInt(12) + 1;
  const day = randomInt(31) + 1;
  const year = randomInt(new Date().getFullYear() - 1);
  return `${month}/${day}/${pad(year, 4)}`;
}


function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
