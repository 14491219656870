export function ProfilePic({ profile }) {
  const {
    backgroundImage,
    name,
    portraitImage
  } = profile;


  return (
    <div className="profile-pic-image">
      <img className="backgroundPic" src={backgroundImage} alt={'Profile pic for ' + name} />
      <img className="characterPic" src={portraitImage} alt={'Profile pic for ' + name} />
    </div>
  );
}
