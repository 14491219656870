import React, { useState } from 'react';

export const NotificationContext = React.createContext();

export function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const props = {
    value: {
      showNotification: (message, body, tag, onClick) => {
        if (!window.Notification || Notification.permission !== "granted") {
          return;
        }
        const notification = new Notification(message, { body, renotify: true, requireInteraction: true, tag });
        setNotification(notification);
        notification.onclick = onClick;
      },
      hideNotification: () => {
        if (notification) {
          notification.close();
          setNotification(null);
        }
      }
    }
  };
  return React.createElement(NotificationContext.Provider, props, children);
}
