import React, { useContext, useRef, useState, useEffect } from 'react'
import nobody from './images/nobody.gif';
import { Card } from './Card';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { UserProfileContext } from './UserProfileContext';
import noSinglesBG from './images/no-matches-bg.png';
import noSinglesImage from './images/portraits/skelly.png';

export function Singles() {
  const { params, setAllParams } = useContext(QueryStringRouterContext);
  const {
    approveMatch,
    getPossibleMatches,
    rejectMatch,
    resetRejects
  } = useContext(UserProfileContext);

  const [matches, setMatches] = useState([]);

  const topCard = useRef(null);
  const topCardApprove= useRef(null);
  const topCardReject = useRef(null);

  const profile = matches[0];

  const noSinglesBGStyle = {
    backgroundImage: 'url(' + noSinglesBG + ')',
    backgroundRepeat  : 'repeat-x'
  };

  useEffect(() => {
    getPossibleMatches().then(({ possible_matches: possibleMatches }) => {
      setMatches(possibleMatches);
    }).catch(err => console.error(err));
  }, [getPossibleMatches]);

  const card = profile ?
    <div>
      <h1>Swipe to match!</h1>
      <div className="card-swipe" ref={topCard}>
        <Card profile={profile} />
        <div className="card-swipe-reject" ref={topCardReject} style={{ opacity: 0 }}>ಠ╭╮ಠ</div>
        <div className="card-swipe-approve" ref={topCardApprove} style={{ opacity: 0 }}>♥‿♥</div>
      </div>
    </div>
    :

    <div className="container empty-state no-singles">
    <div className="scrolling-bg" style={noSinglesBGStyle}></div>
    <div className="on-top-of-scrolling-bg">
    <img className="empty-state-image hover" src={noSinglesImage} alt="Spooky Singles – nobody to chat with yet."/>
      <h1>No ghosts left in your area!</h1>

      <em>
        Check the {" "}
        <button className="candy-button" onClick={() => setAllParams(Object.assign({}, params, { view: 'matches' }))}>
          matches
        </button>
        {" "} tab to see<br/>
        who wants to jump your bones!
      </em>

      <br/>
      <p>Want more creeps to chat with?<br/>
        <span className="inviteText">Invite your friends to:<br/><strong>spooky.singles</strong></span><br/>
        -or -<br/>
        <button className="candy-button" onClick={() => {
          resetRejects()
            .then(({ possible_matches: possibleMatches }) => {
              setMatches(possibleMatches);
            })
            .catch(err => console.error(err))
        }}>👻 Reset ghouls you rejected</button>
      </p>

      </div>
    </div>;

  useEffect(() => {
    let dragging = false;
    let startX = 0;
    let x = 0;

    function handleMouseDown(e) {
      e.preventDefault();
      dragging = true;
      startX = getX(e);
    }
    function handleMouseMove(e) {
      if (!dragging) {
        return;
      }
      x = getX(e);
      if (x === startX) {
        return;
      }

      const dx = x - startX;
      const deg = dx / 10;
      topCard.current.style.transform = `translateX(${dx}px) rotate(${deg}deg)`;

      const opacity = dx / 100;
      const rejectOpacity = (opacity >= 0) ? 0 : Math.abs(opacity);
      const approveOpacity = (opacity <= 0) ? 0 : opacity;
      topCardReject.current.style.opacity = rejectOpacity;
      topCardApprove.current.style.opacity = approveOpacity;
    }
    function handleMouseUp(e) {
      if (!dragging) {
        return;
      }
      dragging = false;
      if (x === startX) {
        return;
      }

      topCardReject.current.style.opacity = 0;
      topCardApprove.current.style.opacity = 0;

      const threshold = 80;
      const dx = x - startX;
      if (dx < -threshold) {
        topCard.current.style.transition = "";
        topCard.current.style.transform = "translateX(0) rotate(0)";
        rejectMatch(profile.userId);
        setMatches(matches.slice(1));
      } else if (dx > threshold) {
        topCard.current.style.transition = "";
        topCard.current.style.transform = "translateX(0) rotate(0)";
        approveMatch(profile.userId);
        setMatches(matches.slice(1));
      } else {
        topCard.current.style.transition = "transform 0.3s";
        topCard.current.style.transform = "translateX(0) rotate(0)";
      }
    }

    const elem = topCard.current;
    if (!elem) {
      return;
    }
    elem.addEventListener("mousedown", handleMouseDown);
    elem.addEventListener("touchstart", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      elem.removeEventListener("mousedown", handleMouseDown);
      elem.removeEventListener("touchstart", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [card]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container singles">
      {card}
    </div>
  );
}

function getX(e) {
  if (e.touches) {
    return e.touches[0].pageX;
  }
  return e.pageX;
}
