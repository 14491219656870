import { useEffect, useRef } from 'react';

export function useMessageHandler(channel, event, handler) {
  const handlerFun = useRef(handler);
  handlerFun.current = handler;

  useEffect(() => {
    if (channel === null) {
      return;
    }

    // console.log(`adding handler for ${event}`)
    const ref = channel.on(event, message => {
      handlerFun.current(message);
    });

    return () => {
      // console.log(`removing handler for ${event}`)
      channel.off(event, ref);
    }
  }, [channel, event]);
}
