import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatMessage } from './ChatMessage';
import { Keyboard } from './Keyboard';
import { ProfilePic } from './ProfilePic';
import { UnreadNotificationContext } from './UnreadNotificationContext';
import { sendMessage, useChannel } from './useChannel';
import { useMessageHandler } from './useMessageHandler';

export function ChatWindow({ profile, otherProfile }) {
  const {
    name
  } = otherProfile;

  const messageWindow = useRef();

  const uids = [profile.userId, otherProfile.userId];
  uids.sort();

  const [messages, setMessages] = useState([]);

  const { channel: chatChannel } = useChannel('chat:' + uids.join(':'), undefined, (channel, { messages: msgs}) => {
    setMessages(msgs);
    messageWindow.current.scrollTop = messageWindow.current.scrollHeight;
  });

  const { clearUnreadNotification, unreadNotifications } = useContext(UnreadNotificationContext);
  useEffect(() => {
    if (unreadNotifications[otherProfile.userId]) {
      clearUnreadNotification(otherProfile.userId);
    }
  });

  useMessageHandler(chatChannel, 'new_message', message => {
    setMessages(messages.slice(0).concat([message]));
    messageWindow.current.scrollTop = messageWindow.current.scrollHeight;
  });

  return (
    <div className="chat-window">
      <h4 className="header row">
        <div className="col-3"><ProfilePic profile={otherProfile} /></div>
        <div className="col-9 name"><span>Your chat with:</span> {name}</div>
      </h4>
      <div className="chat-messages-container" ref={messageWindow}>
        <div className="chat-messages" >
          {messages.map(({ date, user_id, message }) => <ChatMessage key={date} myUserId={profile.userId} sender={user_id} message={message} />)}
        </div>
      </div>
      <Keyboard onSend={message => {
        sendMessage(chatChannel, 'send_message', { message });
        const audio = new Audio("/sounds/send-message.mp3");
        audio.play().catch(err => { console.error(err); });
      }} />
    </div>
  );
}
