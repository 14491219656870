import React, { useRef, useState } from 'react';
import emojis from './data/emojis.json';
import questions from './data/questions.json';
import answers from './data/answers.json';
import smallTalk from './data/smallTalk.json';
import spicy from './data/spicy.json';
import { randomElement } from './random';

export function Keyboard({ onSend }) {
  const input = useRef(null);
  const [mode, setMode] = useState("sentence");

  const modeButton = mode === "sentence"
    ? <button onClick={() => { input.current.value = ""; setMode("emoji")}} className="candy-button keyboard-mode" type="button">Mode<span className="modeEmoji">😃</span></button>
    : <button onClick={() => setMode("sentence")} className="candy-button keyboard-mode" type="button">Mode<span className="modeEmoji">💬</span></button>;

  const keys = mode === "sentence"
    ? <SentenceKeyboard input={input} />
    : <EmojiKeyboard input={input} />;

  function sendMessage() {
    const message = input.current.value.trim()
    if (message.length > 0) {
      onSend(message);
    }
    input.current.value = "";
  }

  return (
    <div className="keyboard">
        <div className="input-group text-row">

          <textarea rows="3" disabled="disabled" ref={input} type="text" className="form-control" placeholder="Use the buttons below to chat." aria-label="Recipient's username" aria-describedby="basic-addon2"/>
          <div className="input-group-append">
            <button onClick={sendMessage} className="candy-button send" type="button">Send</button>
          </div>
        </div>

        <div className="row actual-keyboard">
          <div className="col-2">
            {modeButton}
          </div>
          <div className="col-10 keys">
            {keys}
          </div>
        </div>
    </div>
  );
}

function EmojiKeyboard({ input }) {
  return [
    <div className="row emojis" key="emojis">
      {emojis.map(emoji => <button className="candy-button" key={emoji} onClick={() => input.current.value += emoji}>{emoji}</button>)}
    </div>,
    <div className="row" key="meta">
      <button className="candy-button space" onClick={() => input.current.value += " "}>Space</button>
      <button className="candy-button" onClick={() => input.current.value = Array.from(input.current.value).slice(0, -1).join("")}>Delete</button>
    </div>
  ];
}

const questionSuffixes = [
  "Wrong answers only."
];

function SentenceKeyboard({ input }) {
  return (
    <div>
      <div className="row">
        <RandomTextButton input={input} items={questions} suffixes={questionSuffixes}><span className="emojiOnButton">❓</span> Questions</RandomTextButton>
        <RandomTextButton input={input} items={answers}><span className="emojiOnButton">✅</span> Answers</RandomTextButton>
      </div>
      <div className="row">
        <RandomTextButton input={input} items={smallTalk}><span className="emojiOnButton">💬</span> Small talk</RandomTextButton>
        <RandomTextButton input={input} items={spicy}><span className="emojiOnButton">🌶</span> Spicy</RandomTextButton>
      </div>
    </div>
  );
}

function RandomTextButton({ children, input, items, suffixes }) {
  return (
    <button className="candy-button" onClick={() => {
      let text = randomElement(items);
      const suffix = Math.random() < 0.02 ? randomElement(suffixes || []) : "";
      input.current.value = [text, suffix].join(" ");
    }}>
      {children}
    </button>
  );
}
