import { ProfilePic } from './ProfilePic';

export function Card({
  nextBackgroundImage,
  nextBio,
  nextDeathDate,
  nextName,
  nextPortraitImage,
  profile,
}) {
  const {
    bio,
    deathDate,
    name,
  } = profile;

  const nextBackgroundImageButton = nextBackgroundImage ? <button className="candy-button" onClick={nextBackgroundImage}>🖼 &#8635;</button> : null;
  const nextBioButton = nextBio ? <button  className="hoverButton candy-button" onClick={nextBio}>&#8635;</button> : null;
  const nextDeathDateButton = nextDeathDate ? <button  className="hoverButton candy-button" onClick={nextDeathDate}>&#8635;</button> : null;
  const nextNameButton = nextName ? <button  className="hoverButton candy-button" onClick={nextName}>&#8635;</button> : null;
  const nextPortraitImageButton = nextPortraitImage ? <button  className="candy-button" onClick={nextPortraitImage}>👻 &#8635;</button> : null;

  const [m, d, y] = deathDate.split("/");
  const diedYearsAgo = Math.floor((new Date() - new Date(y, m, d)) / 1000 / 60 / 60 / 24 / 365);

  return (
    <div className="card">
      <div className="profilePic">
        <ProfilePic profile={profile} />
        <div className="picButtons">
          {nextBackgroundImageButton}
          {nextPortraitImageButton}
        </div>
        <p className="font-italic editHelpMsg hidden"><span className="inner"><strong><span className="emoji">👋</span> This is your spooky dating profile!</strong>Select what you like from the options below.<br/> Other ghouls can already see you in their 'singles' tab!</span>
        <button className="closeX">X</button>
        </p>
      </div>
    <div className="cardInner">

      <h2 className="name">{nextNameButton}{name}</h2>
      <p className="deathDate">{nextDeathDateButton}Died {diedYearsAgo} years ago.</p>

      <p className="bio">{nextBioButton}{bio}</p>
    </div>
    </div>
  );
}
