import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from './NotificationContext';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { UnreadNotificationContext } from './UnreadNotificationContext';
import { backgroundPictures, portraitPictures, generateBackgroundImage, generateBio, generateDeathDate, generateName, generatePortraitImage } from './profileGenerator';
import { randomElement } from './random';
import { sendMessage, useChannel } from './useChannel';
import { useMessageHandler } from './useMessageHandler';

export const UserProfileContext = React.createContext();

export function UserProfileProvider({ children, userId }) {
  const { showNotification } = useContext(NotificationContext);
  const { setUnreadNotification } = useContext(UnreadNotificationContext);
  const { params, setAllParams } = useContext(QueryStringRouterContext);
  const [ isNewUser, setIsNewUser ] = useState(window.localStorage.getItem('profile') === null);
  const { channel: userProfileChannel } = useChannel('user_profile:' + userId, undefined, (channel) => {
    sendMessage(channel, 'update_profile', { profile: profile });
  });
  const [profile, setProfile] = useState(getProfile(userId));
  const [matches, setMatches] = useState({});

  if (isNewUser) {
    setAllParams({ view: "welcome" });
    setIsNewUser(false);
  }

  useEffect(() => {
    if (userProfileChannel === null) {
      return;
    }

    sendMessage(userProfileChannel, 'get_mutual_matches')
      .then(({ mutual_matches: mutualMatches }) => {
        const mutualMap = mutualMatches.reduce((acc, profile) => {
          acc[profile.userId] = profile;
          return acc;
        }, {});
        setMatches(mutualMap);
      }).catch(err => console.error(err));
  }, [userProfileChannel]);

  useMessageHandler(userProfileChannel, 'new_mutual_match', profile => {
    setMatches(Object.assign({}, matches, {[profile.userId]: profile}));
    setUnreadNotification(profile.userId);

    const audio = new Audio(randomElement([
      "/sounds/new-match.mp3",
      "/sounds/new-match-2.mp3",
      "/sounds/new-match-3.mp3",
      "/sounds/new-match-4.mp3",
      "/sounds/new-match-5.mp3",
      "/sounds/new-match-6.mp3"
    ]));
    audio.play().catch(err => { console.error(err); });

    if (params.view !== "matches" || document.hidden) {
      showNotification(`New match with ${profile.name}!`, `Click to start chatting with ${profile.name}`, "new-match", () => setAllParams({ view: 'chat', with: profile.userId }));
    }
  });

  useMessageHandler(userProfileChannel, 'new_message', message => {
    const alreadyChatting = params.view === "chat" && params.with === message.user_id;
    if (!alreadyChatting) {
      setUnreadNotification(message.user_id);
    }

    const audio = new Audio("/sounds/message-beep.mp3");
    audio.play().catch(err => { console.error(err); });

    if (!alreadyChatting || document.hidden) {
      const otherProfile = matches[message.user_id];
      showNotification(`New message from ${otherProfile.name}`, message.message, "new-message", () => setAllParams({ view: 'chat', with: message.user_id }));
    }
  });

  const updateChannel = (key, fn) => {
    const newProfile = updateFromFn(profile, key, fn);
    setProfile(newProfile);
    sendMessage(userProfileChannel, 'update_profile', { profile: newProfile });
    window.localStorage.setItem('profile', JSON.stringify(newProfile));
  };

  const props = {
    value: {
      userProfileChannel,
      matches,
      profile,
      getPossibleMatches: () => {
        if (userProfileChannel) {
          return sendMessage(userProfileChannel, 'get_possible_matches');
        } else {
          return Promise.resolve({ possible_matches: [] });
        }
      },
      approveMatch: (userId) => {
        return sendMessage(userProfileChannel, 'approve_match', { userId });
      },
      rejectMatch: (userId) => {
        return sendMessage(userProfileChannel, 'reject_match', { userId });
      },
      resetRejects: (userId) => {
        return sendMessage(userProfileChannel, 'reset_rejects');
      },
      nextBackgroundImage: () => updateChannel('backgroundImage', () => {
        let index = backgroundPictures.indexOf(profile.backgroundImage);
        index = (index + 1) % backgroundPictures.length;
        return backgroundPictures[index];
      }),
      nextBio: () => updateChannel('bio', generateBio),
      nextDeathDate: () => updateChannel('deathDate', generateDeathDate),
      nextPortraitImage: () => updateChannel('portraitImage', () => {
        let index = portraitPictures.indexOf(profile.portraitImage);
        index = (index + 1) % portraitPictures.length;
        return portraitPictures[index];
      }),
      nextName: () => updateChannel('name', generateName),
    }
  };

  return React.createElement(UserProfileContext.Provider, props, children);
}

function getProfile(userId) {
  let profile = {
    backgroundImage: generateBackgroundImage(),
    bio: generateBio(),
    deathDate: generateDeathDate(),
    name: generateName(),
    portraitImage: generatePortraitImage(),
    userId
  };
  const profileStr = window.localStorage.getItem('profile');
  if (profileStr) {
    profile = JSON.parse(profileStr);
    profile.userId = userId;
  } else {
    window.localStorage.setItem('profile', JSON.stringify(profile));
  }
  return profile;
}

function updateFromFn(obj, key, fn) {
  const current = obj[key];
  let val;
  do {
    val = fn();
  } while(val === current);
  return update(obj, key, val);
}

function update(obj, key, val) {
  return Object.assign({}, obj, {[key]: val});
}
