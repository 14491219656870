import React, { useContext } from 'react';
import { Chat } from './Chat';
import { Matches } from './Matches';
import { MyProfile } from './MyProfile';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { Singles } from './Singles';
import { Welcome } from './Welcome';

export function ViewPicker() {
  const { params } = useContext(QueryStringRouterContext);
  const view = params.view || 'my-profile';

  switch (view) {
    case 'chat':
      return <Chat />;

    case 'singles':
      return <Singles />;

    case 'matches':
      return <Matches />;

    case 'welcome':
      return <Welcome />;

    case 'my-profile':
    default:
      return <MyProfile />;
  };
}
