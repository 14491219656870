import React, { useEffect, useState } from 'react';
import { Socket } from 'phoenix';

export const SocketContext = React.createContext();

export function SocketProvider({ children, options, url }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const s = new Socket(url, options);
    s.connect();
    // s.onOpen(() => console.log('the connection is open'));
    // s.onError(() => console.log('there was an error with the connection!'));
    // s.onClose(() => console.log('the connection dropped'));
    setSocket(s);

    return () => {
      s.disconnect();
      setSocket(null);
    }
  }, [options, url]);

  const props = {
    value: {
      socket
    }
  };

  return React.createElement(SocketContext.Provider, props, children);
}
