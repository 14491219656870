import React, { useContext } from 'react'
import { Card } from './Card';
import { UserProfileContext } from './UserProfileContext';

export function MyProfile() {
  const {
      profile,
      nextBackgroundImage,
      nextBio,
      nextDeathDate,
      nextPortraitImage,
      nextName,
  } = useContext(UserProfileContext);

  return (
    <div className="container profile clearfix">
      <Card
        nextBackgroundImage={nextBackgroundImage}
        nextBio={nextBio}
        nextDeathDate={nextDeathDate}
        nextPortraitImage={nextPortraitImage}
        nextName={nextName}
        profile={profile}
      />
    </div>
  );
}
