import './App.css';
import logo from './images/logo.png';
import { QueryStringRouterProvider } from '@ericlathrop/react-query-string-router';
import { NotificationProvider } from './NotificationContext';
import { UnreadNotificationProvider } from './UnreadNotificationContext';
import { SocketProvider } from './SocketContext';
import { TopNav } from './TopNav';
import { UserProfileProvider } from './UserProfileContext';
import { ViewPicker } from './ViewPicker';
import { v4 as uuidv4 } from 'uuid';

let userId = window.localStorage.getItem('userId');
if (!userId) {
  userId = uuidv4();
  window.localStorage.setItem('userId', userId);
}
console.log(`UserId: ${userId}`);

// thanks safari!
function playSilentSound() {
  const audio = new Audio("/sounds/the-sound-of-silence.mp3");
  audio.play().catch(err => { console.error(err); });
  document.body.removeEventListener("click", playSilentSound);
}
document.body.addEventListener("click", playSilentSound);

function App() {
  const socketUrl = window.socketUrl;
  const socketOptions = { params: { 'user_id': userId }};

  return (
    <QueryStringRouterProvider>
      <NotificationProvider>
        <UnreadNotificationProvider>
          <SocketProvider url={socketUrl} options={socketOptions}>
            <UserProfileProvider userId={userId}>
              <div className="App">
              <div className="logo">
                <img src={logo} alt="Spooky Singles"/>
              </div>
                <TopNav />
              </div>
              <ViewPicker />
            </UserProfileProvider>
          </SocketProvider>
        </UnreadNotificationProvider>
      </NotificationProvider>
      <footer className="footer">
      <p>&copy; 2020 <a href="http://twoscoopgames.com">Two Scoop Games</a></p>
      </footer>
    </QueryStringRouterProvider>
  );
}

export default App;
