import React, { useState } from 'react'

export function EnableNotificationButton() {
  const [asked, setAsked] = useState(false);

  if (asked || !window.Notification || window.Notification.permission !== "default") {
    return null;
  }

  return (
    <button className="candy-button" onClick={() => Notification.requestPermission().then(() => setAsked(true))}>
      Enable notifications
    </button>
  )
}
