import React, { useContext } from 'react'
import { ChatWindow } from './ChatWindow';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { UserProfileContext } from './UserProfileContext';
import noChatBG from './images/no-chat-bg.png';
import noChatImage from './images/portraits/booty.png';

export function Chat() {
  const { params } = useContext(QueryStringRouterContext);

  const {
    matches,
    profile
  } = useContext(UserProfileContext);

  const otherUserProfile = matches[params.with];

  const noChatBGStyle = {
    backgroundImage: 'url(' + noChatBG + ')',
    backgroundRepeat  : 'repeat-x'
  };


  const noChat = (
    <div className="container empty-state no-chat">
    <div className="scrolling-bg" style={noChatBGStyle}></div>
    <div className="on-top-of-scrolling-bg">
    <img className="empty-state-image hover" src={noChatImage} alt="Spooky Singles – nobody to chat with yet."/>
      <h1>No hotties to chat with yet!</h1>
      <p>In the meantime:</p>
      <ol>
      <li>Perfect your profile</li>
      <li>Swipe to match with others in the "singles" tab!</li>
      <li>Invite your friends to spooky.singles!</li>
      </ol>
      </div>
    </div>
  );
  const chatWindow = otherUserProfile ? <ChatWindow profile={profile} otherProfile={otherUserProfile} /> : noChat;

  return (
    <div className="container chat">
      {chatWindow}
    </div>
  );
}
