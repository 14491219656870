import React, { useContext } from 'react';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import bats from './images/welcome-bg.png';
import imageLogo from './images/spooky-singles-logo.png';

export function Welcome() {
  const { setAllParams } = useContext(QueryStringRouterContext);
  const welcomeBGStyle = {
    backgroundImage: 'url(' + bats + ')',
    backgroundRepeat  : 'repeat-x'
  };

  return (
    <div className="container empty-state welcome">
    <div className="scrolling-bg" style={welcomeBGStyle}></div>
    <div className="on-top-of-scrolling-bg">
    <img className="empty-state-image  hover" src={imageLogo} alt="Welcome to spooky.singles!"/>
      <h1>Welcome, New Ghoul!</h1>
      <p>Spooky.singles is the official dating app of the afterlife!</p>
      <ol>
      <li>Create a profile</li>
      <li>Swipe to match with others</li>
      <li>Chat and have fun!</li>
      </ol>
      <p>
        We've generated a chilling random profile to get you started, but feel free to edit it to your cold dead hearts content on the:</p>
        <button className="candy-button" onClick={() => setAllParams({ view: "my-profile" })}>My Profile Tab</button>

      </div>
    </div>
  );
}
