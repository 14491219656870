import React, { useContext } from 'react';
import { ProfilePic } from './ProfilePic';
import { UnreadNotificationContext } from './UnreadNotificationContext';
import { UnreadNotificationIcon } from './UnreadNotificationIcon';

export function SmallCard({ onClick, profile }) {
  const { unreadNotifications } = useContext(UnreadNotificationContext);

  const {
    name
  } = profile;

  let unreadNotificationIcon;
  if (unreadNotifications[profile.userId]) {
    unreadNotificationIcon = <UnreadNotificationIcon />;
  }

  return (
    <div className="small-card row" onClick={onClick}>
      <div className="col-4">
        <ProfilePic profile={profile} />
      </div>
      <div className="col-8">
        <h2 className="name">Chat with {name}</h2>
      </div>
      {unreadNotificationIcon}
    </div>
  );
}
