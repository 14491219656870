import React, { useContext } from 'react';
import { EnableNotificationButton } from './EnableNotificationButton';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { SmallCard } from './SmallCard';
import { UserProfileContext } from './UserProfileContext';
import noMatchesBG from './images/no-matches-bg.png';
import noMatchesImage from './images/portraits/slender.png';


export function Matches() {
  const { setAllParams } = useContext(QueryStringRouterContext);
  const {
    matches
  } = useContext(UserProfileContext);

  const noMatchesBGStyle = {
    backgroundImage: 'url(' + noMatchesBG + ')',
    backgroundRepeat  : 'repeat-x'
  };

  let noMatches;
  if (Object.keys(matches).length === 0) {
    noMatches = (
      <div className="container empty-state no-matches">
      <div className="scrolling-bg" style={noMatchesBGStyle}></div>
      <div className="on-top-of-scrolling-bg">
      <img className="empty-state-image hover" src={noMatchesImage} alt="Spooky Singles – nobody to chat with yet."/>
        <h1>No matches yet.</h1>
        <p>In the meantime:</p>
        <ol>
        <li>Perfect your profile</li>
        <li>Swipe to match with others in the "singles" tab!</li>
        <li>Invite your friends to spooky.singles!</li>
        </ol>
        </div>
      </div>
    );
  }

  return (
    <div className="container matches mutual-matches">
      <EnableNotificationButton />
      {noMatches}
      {Object.keys(matches).map(userId => <SmallCard key={userId} onClick={() =>
          setAllParams({ view: 'chat', with: userId })} profile={matches[userId]} />)}
    </div>
  );
}
