import React, { useContext } from 'react';
import { QueryStringRouterContext } from '@ericlathrop/react-query-string-router';
import { UnreadNotificationContext } from './UnreadNotificationContext';
import { UnreadNotificationIcon } from './UnreadNotificationIcon';

export function TopNav() {
  const { params, setAllParams } = useContext(QueryStringRouterContext);
  const { unreadNotifications } = useContext(UnreadNotificationContext);

  function setView(view) {
    setAllParams(Object.assign({}, params, { view }));
  }

  let matchesIcon;
  if (Object.keys(unreadNotifications).length > 0) {
    matchesIcon = <UnreadNotificationIcon />;
  }

  let chatIcon;
  if (unreadNotifications[params.with]) {
    chatIcon = <UnreadNotificationIcon />;
  }

  return (
    <ul className="topNav">
      <li>
        <button className="btn profile" onClick={() => setView('my-profile')}>My Profile</button>
      </li>
      <li>
        <button className="btn singles"  onClick={() => setView('singles')}>Singles</button>
      </li>
      <li>
        <button className="btn matches"  onClick={() => setView('matches')}>
          Matches
          {matchesIcon}
        </button>
      </li>
      <li>
        <button className="btn chat"  onClick={() => setView('chat')}>
          Chat
          {chatIcon}
        </button>
      </li>
    </ul>
  );
}
