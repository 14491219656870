import React, { useState } from 'react';

export const UnreadNotificationContext = React.createContext();

export function UnreadNotificationProvider({ children, userId }) {
  const [unreadNotifications, setUnreadNotifications] = useState({});

  const props = {
    value: {
      clearUnreadNotification: key => {
        let newUnreadNofications = Object.assign({}, unreadNotifications);
        delete newUnreadNofications[key];
        setUnreadNotifications(newUnreadNofications);
      },
      setUnreadNotification: key => {
        setUnreadNotifications(Object.assign({}, unreadNotifications, {[key]: true}));
      },
      unreadNotifications
    }
  };

  return React.createElement(UnreadNotificationContext.Provider, props, children);
}
